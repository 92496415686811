import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styles from './allied-health-bullets-section.module.scss';

export default ({ topFluid, bottomFluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            What it means to be an allied health traveler
          </h3>
          <div className="content">
            <p className={`content`}>
              As an allied health traveler, you need a travel agency that will
              put your needs first. Not only are our travelers some of the
              highest-paid in the industry, you’ll also enjoy great employee
              benefits and personalized service.
            </p>
            <p className={`content ${styles.subheader}`}>
              We are proud to have one of the highest retention rates in the
              industry!
            </p>
            <p className="content">
              Here are just a few of the reasons our travelers trust Trustaff to
              accelerate their careers again and again:
            </p>
            <ul className={`is-size-7`}>
              <li>
                Priority access to thousands of{' '}
                <Link to="/jobs/">jobs nationwide</Link> – all settings and
                modalities
              </li>
              <li>Industry-leading pay</li>
              <li>Guaranteed weekly hours</li>
              <li>Experienced, tenured recruiters</li>
              <li>
                Comprehensive{' '}
                <Link to="/allied-health/benefits/">benefits</Link> for you and
                your loved ones including options for medical, dental, vision,
                401k with employer match, short/long-term disability, &amp; more
              </li>
              <li>
                Step-by-step assistance with compliance, housing, and travel
              </li>
              <li>
                <Link to="/allied-licensing-special/">
                  License reimbursement
                </Link>
              </li>
              <li>
                Career tools, professional advocacy, and wellness resources
                through the Ingenovis Health{' '}
                <Link to="/ACTprogram/">ACT program</Link>{' '}
              </li>
              <li>
                Exclusive{' '}
                <Link to="/perks-discounts/">
                  employee perks &amp; discounts
                </Link>
              </li>
              <li>Clinical Liaison support throughout every assignment</li>
              <li>
                Plus the opportunity to earn additional{' '}
                <Link to="/allied-health/salary-benefits/">
                  allied traveler bonuses
                </Link>
                , including completion, referral, and more depending on your
                facility of choice!
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={topFluid}
            className={styles.aboutImageWrapper}
            alt="allied health travel locations"
          />
          <Img
            fluid={bottomFluid}
            className={`is-hidden-mobile ${styles.aboutImageWrapper} ${styles.img2}`}
            alt="allied health assignments"
          />
        </div>
      </div>
    </div>
  );
};
