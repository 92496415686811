import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './allied-health-about-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
          Jobs you want. Benefits you deserve. People who care.
          </h3>
          <div className="content">
            <p className="content">
            Traveling is more than just a job; it's a lifestyle.
            When you're in an unfamiliar location, away from family and
            friends, it makes a big difference to have someone who's always on your side.
            </p>
            <p className="content">
            Since so much of our business is built on trust, your satisfaction
            is our number one priority—from handling your paperwork to delivering
            your paycheck, our promise is people who care.
            </p>
          </div>
          <Link to="/apply/" className="button is-primary">
            Get started
          </Link>
        </div>
        <div className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile} is-hidden-mobile`}>
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="travel allied health jobs"
          />
        </div>
      </div>
    </div>
  );
};
