import React from 'react';
import { Link } from 'gatsby';
import styles from './allied-health-closing-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">Ready to get started?</h3>
          <div className="content">
            <p className={`content`}>
              Head over to our job search page, where you can browse{' '}
              <Link to="/jobs/">allied health jobs</Link>, or learn more about
              how becoming a{' '}
              <Link to="/trustaff-traveler/">Trustaff traveler</Link> can help
              you take your career (and your paycheck) to the next level.
            </p>
            <p className="content">
              Questions? We’re happy to walk you through how it all works. Speak
              with a Trustaff recruiter today for a free consultation.
            </p>
          </div>
          <Link to="/jobs/" className="button is-info">
            Search jobs
          </Link>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile} ${styles.testimonial}`}
        >
          <p className={`content ${styles.testimonialtext}`}>
            “This may have been one of the best decisions I have made in my
            nursing career. I am able to do the work I love, meet some amazing
            people, and truly be appreciated by my company.
            <br />
            <br />I am proud to be a #TrustaffTraveler!”
          </p>
          <p className="content">— Allison</p>
        </div>
      </div>
    </div>
  );
};
