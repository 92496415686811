import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './index.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import HeroImageMini from '../../components/hero-image/hero-image-mini';
import AlliedHealthSlider from '../../components/allied-health/allied-health-slider';
import AlliedHealthAboutSection from '../../components/allied-health/allied-health-about-section';
import AlliedHealthBulletsSection from '../../components/allied-health/allied-health-bullets-section';
import AlliedHealthClosingSection from '../../components/allied-health/allied-health-closing-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getAlliedHealthImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath = getAlliedHealthImageEdge(
    'allied-health-travel-jobs-og'
  ).node.childImageSharp.fluid.src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;
  return (
    <>
      <Helmet>
        <title>Become an allied health traveler - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="As the country’s top privately owned allied health staffing agency, Trustaff's travelers are some of the highest-paid in the industry. You deserve the best of both worlds: great pay, comprehensive employee benefits, and outstanding service."
        />
        <meta property="og:title" content="Become an allied health traveler" />
        <meta
          property="og:description"
          content="As the country’s top privately owned allied health staffing agency, Trustaff's travelers are some of the highest-paid in the industry. You deserve the best of both worlds: great pay, comprehensive employee benefits, and outstanding service."
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/allied-health/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={
            getAlliedHealthImageEdge('allied-health-travel-jobs').node
              .childImageSharp.fluid
          }
        >
          <div className={'columns'}>
            <div className="column is-8 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-2 has-text-white`}>
                  Become an allied health traveler
                </h1>
                <p className={`content`}>
                  Whether you’re a Surgical Tech, Cath Lab Tech, Sonographer, or
                  anything in between, becoming an allied health traveler allows
                  you the freedom to take charge of your career—go experience
                  new places, learn new skills, and enhance your paycheck.
                </p>
                <p className={`content`}>
                  Privately-owned and operated, Trustaff has been working with
                  travelers of all types since 2002. We are proud to be
                  recognized as one of the nation’s{' '}
                  <b>largest allied health travel companies</b>. Our experienced
                  allied health recruiters are ready to help you find the
                  high-paying travel job of your dreams.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <AlliedHealthAboutSection
            fluid={
              getAlliedHealthImageEdge('allied-health-sidebar-1').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div className={`section ${styles.sectionBackgroundGrey}`}>
          <AlliedHealthSlider />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <AlliedHealthBulletsSection
            topFluid={
              getAlliedHealthImageEdge('allied-health-sidebar-2').node
                .childImageSharp.fluid
            }
            bottomFluid={
              getAlliedHealthImageEdge('allied-health-sidebar-3').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <AlliedHealthClosingSection />
        </div>
        <HeroImageMini
          fluid={
            getAlliedHealthImageEdge('allied-health-big-mountains').node
              .childImageSharp.fluid
          }
        ></HeroImageMini>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^allied-health/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
